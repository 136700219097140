<template>
	<div class="view view-summary">
		
		<end-game />

	</div>
</template>

<script>
import EndGame from '@/components/game/end-game'

export default {
	name: 'view-summary',
	components: { EndGame }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.view-summary
		.typewriter
			width 100%
			left 0
			height 180px
			.bottom
				height 80px
		.page-wrap
			width 90%
			left 5%
			top 40px
			height calc(100% - 119px)
			.page
				padding 24px


.view-summary
	position fixed
	left 0
	top 0
	right 0
	bottom 0
	height 100%
	width 100%
	z-index 5
	background-color var(--color-summary-bg)

</style>
